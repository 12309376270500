<template>
  <v-container>
    <v-row>
      <v-col class="py-1">
        <div class="d-flex mx-md-6 mt-4 mt-md-6">
          <p class="page__title">
            Purchase Order <v-icon color="#8f96a1">mdi-chevron-right</v-icon>
            <span style="color: #8f96a1">preview</span>
          </p>
          <v-spacer></v-spacer>
          <v-btn @click="$router.go(-1)" text rounded large v-if="$vuetify.breakpoint.mdAndUp">
            <v-icon left>mdi-chevron-left</v-icon> Back
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row class="my-md-6">
      <v-col cols="12" class="py-1">
        <div class="pContainer">
          <div class="d-flex justify-center align-center" style="height: 100vh; width: 100%" v-if="loadingPage">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div>
          <div class="d-flex justify-center align-center flex-column px-md-10" :style="{
            width: `${$vuetify.breakpoint.smAndUp ? '70%' : '100%'}`,
          }" v-else>
            <div class="d-flex align-center justify-end actionTxt py-1" style="width: 100%">
              <v-btn x-small text rounded class="d-flex justify-center align-center pa-2" @click="
                $router.push({
                  path: `/purchase-order/edit/${$route.params.id}`,
                  query: { edit: true },
                })
                ">
                <img :src="require('@/assets/icons/pencilIcon.svg')" alt="edit-doc" />
                <span class="headerBtnTxt px-1">edit</span>
              </v-btn>
              <v-btn x-small text rounded class="d-flex justify-center align-center pa-2" @click="
                $router.push({
                  path: `/purchase-order/edit/${$route.params.id}`,
                  query: { delivery_note: true },
                })
                ">
                <img :src="require('@/assets/icons/pencilIcon.svg')" alt="delivery-note" />
                <span class="headerBtnTxt px-1">Delivery Note</span>
              </v-btn>
              <v-btn x-small text rounded class="d-flex justify-center align-center pa-2" @click="triggerDownload">
                <v-progress-circular indeterminate color="primary" :size="10" :width="2"
                  v-if="loading"></v-progress-circular>
                <img :src="require('@/assets/icons/download-quote.svg')" alt="clone-doc" v-else />
                <span class="headerBtnTxt px-1">download</span>
              </v-btn>
              <attach-document :itemHypnID="$route.params.id" dispatchURI="purchaseorder" entityType="purchaseorder">
                <v-btn x-small text rounded class="d-flex justify-center align-center pa-2">
                  <v-icon left>mdi-view-grid-outline</v-icon> Tools
                </v-btn>
              </attach-document>
            </div>
            <div class="pContainer__card pa-4 pa-md-8 mb-6">
              <POTemplate ref="POTemplateRef" :documentTitle="poDetails.label || ''" :date="poDetails.issued"
                :reference="poDetails.reference || ''" :deliveryRef="poDetails.delivery_reference || ''"
                :deliveryterms="poDetails.delivery_terms || ''" :shipto="poDetails.ship_to"
                :vendorRef="poDetails.vendor_reference || ''" :lines="lineItems" :ponumber="poDetails.po_number"
                :client_custom1="poDetails.attention" :currencySym="poDetails.currency" :total="poDetails.total"
                :company_to="companydetails.company_name" :address_to="companydetails.address || ''"
                :zip_to="companydetails.postcode || ''" :city_to="companydetails.city || ''"
                :country_to="companydetails.country || ''" :company_from="$orgDetails.company.company_name || ''"
                :organizationLogo="$orgDetails.logo" :address_from="$orgDetails.office[0].address || ''"
                :zip_from="$orgDetails.office[0].postcode || ''" :country_from="$orgDetails.office[0].country || ''"
                :showTax="poDetails.taxed" :deliveryLeadTime="poDetails.expected_delivery_date
" />
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import POTemplate from "./components/POTemplate.vue";
import downloadPdfMixin from "@/mixins/downloadPdfMixin";
import AttachDocument from "@/components/attachDocument.vue";

export default {
  name: "previewPurchaseOrder",
  components: {
    POTemplate,
    AttachDocument,
  },
  data() {
    return {
      loadingPage: false,
      sendingPO: false,
      loading: false,
    };
  },
  mixins: [downloadPdfMixin],
  computed: {
    ...mapGetters({
      poDetails: "purchaseorder/getSinglePO",
      customer: "contacts/getCustomer",
    }),
    lineItems() {
      return this.poDetails.lines.map((line) => ({
        addon: line.addon,
        description: line.description,
        quantity: line.quantity,
        UOM: line.uom,
        price: line.price.unit,
        total: line.price.total,
        material_no: line.material_no,
      }));
    },
    companydetails() {
      return {
        company_name: this.customer?.data?.company?.company_name || "",
        address: this.customer?.data?.address?.address || "",
        postcode: this.customer?.data?.address?.postcode || "",
        city: this.customer?.data?.address?.city || "",
        country: this.customer?.data?.address?.country || "",
      };
    },
  },
  methods: {
    ...mapActions({ showToast: "ui/showToast" }),



    async triggerDownload() {
      try {
        this.loading = true;
        const uri = "purchaseorder/triggerPODownload";
        const type = "Purchase Order";


        await this.handlePDFDownload(this.$orgHypnId, uri, type)


      } catch (error) {
        console.error("Error downloading PDF:", error);
      } finally {
        this.loading = false;
      }
    },
    async getPO(id) {
      this.loadingPage = true;
      const payload = {
        organization_hypnID: this.$orgHypnId,
        poHypnId: id,
      };
      try {
        await this.$store.dispatch("purchaseorder/getPO", payload);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingPage = !this.poDetails;
      }
    },
    async getCustomerDetails() {
      const id = this.poDetails?.counterparty;
      await this.$store.dispatch("contacts/getSingleCustomer", id);
    },
    async makeInvoice() {
      this.sendingPO = true;
      try {
        const payload = {
          purchaseorderHypnId: this.$route.params.id,
          organization_hypnID: this.$orgHypnId,
        };
        const { data } = await this.$store.dispatch(
          "purchaseorder/convertPOToInvoice",
          payload
        );
        if (process.env.NODE_ENV === "development") {
          console.log(data.id);
        }
        if (data.status === "success") {
          this.showToast({
            sclass: "success",
            show: true,
            message: "Purchase order converted successfully",
            timeout: 3000,
          });
        }
      } catch (error) {
        console.error(error);
        this.showToast({
          sclass: "error",
          show: true,
          message: error.message || "An error occurred",
          timeout: 3000,
        });
      } finally {
        this.sendingPO = false;
      }
    },
  },
  async mounted() {
    await this.getPO(this.$route.params.id);
    await this.getCustomerDetails();
  },

  watch: {
    loading: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val == true) {
          setTimeout(() => {
            val = false;
          }, 3000);
        }
      },
    },
  }
};
</script>

<style lang="scss" scoped>
.page__title {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 39px;
}

.pContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__card {
    background-color: #fff;
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 3%),
      0px 6px 10px 0px rgb(0 0 0 / 3%), 0px 1px 18px 0px rgb(0 0 0 / 3%);
    width: 100%;
    height: auto;
    border: transparent;
    border-radius: 4px;

    .cardTitle {
      font-family: "inter";
      font-size: 20px;
      font-weight: 700;
      color: var(--v-primary-base);
    }
  }
}

.linkWrapper {
  cursor: pointer;
  background-color: #f2f2f2;
  border-radius: 4px;
  border: transparent;
}

.headerBtnTxt {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #19283d;
  text-decoration: underline;
  text-transform: capitalize;
}

@media only screen and (min-width: 600px) {
  .page__title {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 36px;
  }
}

@media only screen and (min-width: 768px) {
  .page__title {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 39px;
  }
}
</style>
